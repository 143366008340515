<template>
  <div style="width: 100%; height: 100%">
    <apexchart :width="Width" :height="Height" type="bar" :options="chartOptions" :series="Series"></apexchart>
  </div>
</template>
<script>
function chartOptions(){
  return {
  chart: {
    type: 'bar',
    animations: {
      enabled: true,
    }
  },
  colors: this.Colors.length!=0 ? this.Colors : undefined,
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: this.Categories,
    labels: {
      style: {
        colors: '#ffffff',
      },
    }
  },
  legend: {
    show: true,
    labels: {
      colors: '#ffffff',
    },
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: true,
      style: {
        colors: '#ffffff',
      },
    }
  },
}    
}

export default {
  name:'SimpleBar',
  props: {
    Series: {
      type: Array,
      require: true,
      default: () => [],
    },
    Categories:{
      type: Array,
      require: true,
      default: () => [],
    },
    Colors:{
      type: Array,
      require: false,
      default: () => [],
    },
    Width:{
      type: String,
      require: true,
      default: 'auto',
    },
    Height:{
      type: String,
      require: true,
      default: 'auto',
    },
  },
  methods:{
  },
  computed:{
    chartOptions
  },
}
</script>